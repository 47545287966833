<template>
  <div>
    <div
      v-if="JSON.stringify(obj)!=='{}'"
      class="overflow-auto"
      id="style-3"
      style="height: 800px;padding:0 0.5rem 0 0;"
    >
      <div style="height: 960px;">
        <div style="padding:1.7rem 0 0 0;" />
        <b-card
          header
          header-border-variant="light"
          header-bg-variant="light"
          align="center"
          class="card-3"
        >
          <b-card-body style="max-width:980px;padding:0px 0px 5px 0px;" class="overflow-auto">
            <b-card-text>
              <img
                class="bimg"
                :src="obj.url!==''? obj.url:catImg('event_1080x540.png')"
              />
              <div v-if="user!==null && show" class="text-right" style="margin-top: -0.8rem;font-size:medium;margin-right: 27%;">
              <a-button type="danger" shape="round" size="small" :loading="iconLoading" @click="enterIconLoading">
              ❤ 加入我的最愛組織團體
              </a-button>
              </div>
              <div class="text-left" style="padding:5px 50px 5px 50px;">                   
                <div v-if="mytheme.length>0">                            
                  <b-badge pill variant="primary">
                    <span v-text="covert('theme',obj.theme)"></span>
                  </b-badge>&ensp;
                  <b-badge pill variant="secondary">
                    <span v-text="obj.title"></span>
                  </b-badge>&ensp;
                  <b-badge pill variant="info">
                    <span v-text="obj.atitle+' | '+obj.descs"></span>
                  </b-badge>                  
                </div>
                <h4>
                  <strong>
                    <span v-text="obj.actitle"></span>
                  </strong>
                </h4>
                <div class="classempy">
                <div v-if="obj.edate==obj.sdate" >
                  <b-icon icon="clock" variant="success"></b-icon> 單日活動
                  <br />
                  &emsp;活動時間 :
                  <span
                    v-text="obj.sdate +' '+  obj.stime.substring(0, 5) +' ~ ' + (obj.edate==obj.sdate?'':obj.edate) +' '+  obj.etime.substring(0, 5)"
                  ></span><br />&emsp;
                  <label
                       class="label-checkbox"
                    >{{ getDay(obj.sdate)}}
                  </label>
                </div>
                <div v-if="obj.edate!=obj.sdate" >
                  <b-icon icon="clock" variant="success"></b-icon> 多天活動
                  <br />
                  &emsp;活動時間 :
                  <span
                    v-text="obj.sdate +' '+  obj.stime.substring(0, 5) +' ~ ' + (obj.edate==obj.sdate?'':obj.edate) +' '+  obj.etime.substring(0, 5)"
                  ></span>&emsp; 
                  <div v-if="obj.datetype==false">                
                    <span  v-for="day in getDateData(obj.acts_actclass) " :key="day" :for="day">
                      <label
                        class="label-checkbox"
                      >{{day}}</label>
                    </span>
                  </div>
                  <div style="margin-top:0.1rem;" v-else>
                    <span  v-for="item in obj.multiples " :key="item.id">
                      <label
                        class="label-checkbox"
                      >{{item.date+' '+item.stime+'~'+item.etime}}</label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="classempy">
                <b-icon icon="door-open" variant="success"></b-icon>主辦單位 :
                <br />&emsp;
                <span v-text="orgName"></span>
              </div>                  
                <div class="classempy">
                  <b-icon icon="geo-alt" variant="success"></b-icon>活動地點 :
                  <br />&emsp;
                  <span v-text="obj.address"></span>
                </div>
                <div class="classempy">
                  <b-icon icon="award" variant="success"></b-icon>獎勵學分 :
                  <br />&emsp;
                  <span
                    v-text="'講師 : '+obj.coach+' / 工作人員 : '+obj.volunteer+' / 參加者 : '+obj.actor"
                  ></span><br/>&emsp; <span style="color: brown;font-size:14px;font-weight: bold;">講師與工作人員如設定不領取學分, 則該次活動就不納入學分計算</span>
                </div>
                <div
                  class="classempy"
                  style="margin-top:0.5rem;margin-bottom:0.5rem;"
                  v-if="obj.checked"
                >
                  <b-icon icon="person-lines-fill" variant="success"></b-icon>報名人員限制 :
                  <br />&emsp;
                  <span v-text="'限制報名人員 : '+obj.value"></span>
                </div>
                <div class="classempy">
                  <b-icon icon="globe2" variant="success"></b-icon>相關連結 :
                  <br />&emsp;
                  <a v-if="obj.sinkurl!==''" :href="obj.sinkurl==''?'#':ensureHttps(obj.sinkurl)" target="_blank" rel="noopener noreferrer">
                    <span v-text="obj.sinkurl==''?'無提供':'請參閱網站資訊'"></span>
                  </a>                  
                </div>
                <div class="classempy">
                  <b-icon icon="phone-vibrate" variant="success"></b-icon>聯絡電話/手機 :
                  <br />&emsp;
                  <span v-text="obj.hphone==''?'無提供':obj.hphone "></span> /
                  <span v-text="obj.mphone==''?'無提供':obj.mphone "></span>
                </div>
                <div class="classempy">
                  <b-icon icon="envelope" variant="success"></b-icon>聯絡 Email :
                  <br />&emsp;
                  <span v-text="obj.email"></span>
                </div>

                <div v-if="obj.fee" class="classempy">
                  <b-icon icon="cash-stack" variant="success"></b-icon>活動費用
                  &emsp;
                  <ul class="s1">
                    <li v-for="item in obj.items" :key="item.id">
                      <span v-text=" '項目名稱 : '+item.title"></span>
                      <br />
                      <span v-text=" '項目說明 : '+item.desc"></span>
                      <br />
                      <span style="font-weight:bold;" v-text=" '項目費用 : '+ item.fee + ' TWD'"></span>
                    </li>
                  </ul>
                </div>
                <b-icon icon="bookmark-star" variant="success"></b-icon>活動介紹
                <hr class="hr-dashed-fixed" />
                <div class="divcss" v-html="obj.content"></div>
                <b-icon icon="grid3x2-gap" variant="success"></b-icon>注意事項
                <p></p>
                <div class="remak" v-text="obj.desc"></div>
                <br />
                <b-icon v-if="obj.coachs.length>0" icon="people" variant="success"></b-icon>
                <span v-if="obj.coachs.length>0" v-text="'講師介紹'" />
                <hr v-if="obj.coachs.length>0" class="hr-dashed-fixed" />
                <div v-for="item in obj.coachs" :key="item.uid">
                  <p class="h6">
                    <b-icon-vinyl-fill></b-icon-vinyl-fill>關於
                  </p>
                  <b-avatar
                    :src="item.url"
                    variant="primary"
                    text="item.name"
                    size="7rem"
                    class="align-baseline"
                  ></b-avatar>
                  <br />姓名 :
                  <span v-text="item.displayname"></span>
                  <br />職稱 :
                  <span v-text="item.title"></span>
                  <br />
                  <br />
                  <p class="h6">
                    <b-icon-grid-fill></b-icon-grid-fill>個人介紹
                  </p>公司名稱 :
                  <span v-text="item.company"></span>
                  <br />嘉賓簡介 :
                  <span v-text="item.desc"></span>
                  <br />
                  <br />
                  <p class="h6">
                    <b-icon-tree-fill></b-icon-tree-fill>媒體連結
                  </p>Facebook :
                  <a v-if="item.finkurl!==''" :href="item.finkurl==''?'#':ensureHttps(item.finkurl)" target="_blank" rel="noopener noreferrer">
                    <span v-text="item.finkurl==''?'無提供':'請參閱網站資訊'"></span>
                  </a>
                  <br />Instagram :
                  <a v-if="item.iinkurl!==''" :href="item.iinkurl==''?'#':ensureHttps(item.iinkurl)" target="_blank" rel="noopener noreferrer">
                  <span v-text="item.iinkurl==''?'無提供':'請參閱網站資訊'"></span>
                </a>
                  <br />
                  <hr class="hrs" />
                </div>
                <b-icon icon="map" variant="success"></b-icon>活動地圖
                <hr class="hr-dashed-fixed" />
                <GmapMap
                  :center="{lat: parseFloat(obj.lat), lng:parseFloat(obj.lng) }"
                  :zoom="17"
                  map-type-id="terrain"
                  class="mapcss"
                >
                  <GmapMarker
                    :position="{lat: parseFloat(obj.lat), lng:parseFloat(obj.lng) }"
                    :clickable="true"
                    :draggable="true"
                  />
                </GmapMap>
              </div>
            </b-card-text>
          </b-card-body>
        </b-card>
        <div style="padding:1.8rem 0 0 0;" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
import MY_theme from "../../assets/json/optiondata.json";
import * as moment from "moment/moment";
//import {navigator} from 'vue2-google-maps'
import { makorgService,userService } from "../../_services"; 
import { gmapApi } from "vue2-google-maps";

export default {
  name: "Inforact",
  props: {
    obj: Object
  },
  data() {
    return {
      xurl: "",
      iconLoading:false,
      mytheme: MY_theme,
      org:null,
      show:false,
      orgName:null,  
      imgs: {
        width: 0,
        height: 0
      },
      center: {
        lat: 25.03746,
        lng: 121.564558
      },
      geocoder: null,
      markers: [
        {
          position: { lat: 20, lng: 20 }
        }
      ],
      weeked:[
          { title: '星期一', value: 1 ,checked : false},
          { title: '星期二', value: 2 ,checked : false},
          { title: '星期三', value: 3 ,checked : false},
          { title: '星期四', value: 4 ,checked : false},
          { title: '星期五' , value: 5 ,checked : false},
          { title: '星期六', value: 6 ,checked : false},
          { title: '星期日', value: 0 ,checked : false},
        ],      
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    google: gmapApi
  },
  methods: {
    ...mapMutations("account", ["updateLove"]),
    geolocation() {
      this.google.geolocation.getCurrentPosition(position => {
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
      });
    },

    ensureHttps(url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return 'https://' + url;
      }
      return url;
    },      

    getgeocode(address) {
      //var geocoder = new VueGoogleMaps.Geocoder();
      var geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status == "OK") {
          this.center.lat = results[0].geometry.location.lat();
          this.center.lng = results[0].geometry.location.lng();
          this.markers[0].position.lat = this.center.lat;
          this.markers[0].position.lng = this.center.lng;
          this.form.center = this.center;
        } else {
          // 若轉換失敗...
          console.log(status);
        }
      });
    },

    locatorButtonPressed() {
      this.google.geolocation.getCurrentPosition(
        position => {
          this.center.lat = position.coords.latitude;
          this.center.lng = position.coords.longitude;
        },
        error => {
          console.log(error.message);
        }
      );
    },

    getmaps(data) {
      console.log(data);
    },

    async enterIconLoading(){
      let us = this.user.data.user
      let tmp  = {id:us.id.toString(),name:this.org.name,ids:this.org.id===undefined?'':this.org.id.toString(),priUrl:this.org.priUrl===undefined?'':this.org.priUrl}
      this.iconLoading = !this.iconLoading
      await this.putData(tmp).then(({ data }) => {
          if(data!==null){
            this.show=false;
            this.updateLove(data.acts_loves);
          }
        });
      this.iconLoading = !this.iconLoading
    },

    groupBy(array, groups, valueKey) {
      var map = new Map();
      groups = [].concat(groups);
      return array.reduce((r, o) => {
        groups.reduce((m, k, i, { length }) => {
          var child;
          if (m.has(o[k])) return m.get(o[k]);
          if (i + 1 === length) {
            child = Object.assign(...groups.map(k => ({ [k]: o[k] })), {
              [valueKey]: 0
            });
            r.push(child);
          } else {
            child = new Map();
          }
          m.set(o[k], child);
          return child;
        }, map)[valueKey] += +o[valueKey];
        return r;
      }, []);
    },

    getDay(va1){
      var day_list = ['日', '一', '二', '三', '四', '五', '六'];
      var date = moment(va1).toDate();
      var day  = date.getDay(); // or "new Date().getDay()";
      return '星期' + day_list[day]
    },
    getWeek(va1,va2,obj){
        var currentDate = moment(va2);
        var stopDate = moment(va1);
        let i=0;
        while (currentDate <= stopDate) {
            var tmp = moment(currentDate).toDate();
            var day  = tmp.getDay(); // or "new Date().getDay()";          
            let rs = obj.filter(el => el.value === day);
            if(rs[0].checked){
              for (let i = 0; i < this.weeked.length; i++) {
                if(this.weeked[i].value==day){
                  this.weeked[i].checked=rs[0].checked;
                  break;
                }
              }
            }
            i++;
            currentDate = moment(currentDate).add(1, 'days');
            if(i>8){
              break;
            }
        }
        return this.weeked
    },    

    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },      

    covert(type, val) {
      if (type == "theme") {
        let rs = this.mytheme.filter(el => {
          return el.value === val;
        });
        return rs[0].title;
      }
    },
    async getOrg(id){
      let tmp= await this.queryData(id);
      this.orgName=tmp.data.name
      this.$emit("loadings",false)
      this.org = tmp.data 
      if(JSON.stringify(this.org)!=='{}' && this.user!==null){
        if(this.user.data.user.acts_loves!==null){
          let rs = this.user.data.user.acts_loves.filter(el => {
         return el.ids === this.org.id;
        });
          if(rs.length > 0) {
            this.show = false;
          }else{
            this.show = true;
          }
        }else{
          this.show = true;
        }

      }
    },
    queryData(id) {
        return new Promise((resolve) => {
            resolve(makorgService.findorg(id));
        });
     }, 
     
     putData(obj){
        return new Promise((resolve) => {
            resolve(userService.putUser(obj));
        });
        },
        
        getDayOfWeek(dateString) {
      const date = new Date(dateString);
      const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      return days[date.getDay()];
    },
    
    getDateData(myDatelist){

      const dataWithDayOfWeek = myDatelist.map(item => {
          return {
              ...item,
              dayOfWeek: this.getDayOfWeek(item.sdate)
          };
      });
      const groupedData = dataWithDayOfWeek.reduce((groups, item) => {
          const { dayOfWeek } = item;
          if (!groups[dayOfWeek]) {
              groups[dayOfWeek] = [];
          }
          groups[dayOfWeek].push(item);
          return groups;
      }, {});

      //const propertyNames = [];
      const keysArray = Object.keys(groupedData);
      return keysArray

    }        

  },
  mounted() {
        this.getOrg(this.obj.orgid);
  },
  beforeDestroy() {}
};
</script>
<style scoped>
.card-body {
  padding: 5px 0px 5px 0px;
}
.ddiv {
  padding: 7px;
  border-radius: 17px;
  margin: 5px auto;
  box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
  transition: all 0.3s ease;
}
.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.bimg {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.classempy {
  padding: 0;
}

.remak {
  background-color: #fbfffd;
  border-width: 1px;
  border-style: dotted;
  border-color: #d0d0d0;
  padding: 10px;
}

.hr-dashed-fixed {
  border: 0;
  padding-top: 1px;
  background: repeating-linear-gradient(
    to right,
    #a2a9b6 0px,
    #a2a9b6 4px,
    transparent 0px,
    transparent 10px
  );
}

.bton {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: 40px;
  position: absolute;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  bottom: 1750px;
  left: 26%;
  right: 0;
}
div#home a:link {
  color: #578cc9;
}
div#home a:visited {
  color: #578cc9;
}
div#home a:hover {
  color: #578cc9;
}
div#home a:active {
  color: #578cc9;
}
.mapcss {
  width: 100%;
  height: 500px;
  border: 1px solid rgb(178, 178, 182);
  box-sizing: border-box;
}
.s1 {
  list-style-type: decimal;
  font-size: 14px;
}
.label-checkbox {
  margin-right: 0.4rem;
  margin-left: auto;
  border: 0.02rem solid #4273de;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 2px 7px;
  text-align: center;
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4273de;
}
</style>
<style>
.divcss img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}
.hrs {
  width: 250px;
  height: 1px;
  border-width: 0;
  color: #3d7878;
  background: repeating-linear-gradient(
    to right,
    #a2a9b6 0px,
    #a2a9b6 4px,
    transparent 0px,
    transparent 10px
  );
}
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(152, 167, 189, 0.3);
  background-color: #f5f5f5;
}
#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
#style-3::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
}
#style-3 {
  text-align: left;
}
</style>

